import eNairaIconPath from "../icons/enaira.webp";
import momoIcon from "../icons/momo.svg";
import pocketIcon from "../icons/pocket.svg";
import palmPayIcon from "../icons/palmpay.svg";

export type CustomerWalletType =
  | "momo"
  | "enaira"
  | "palmpay"
  | "pocket"
  | "other";

export interface CustomerWalletOption {
  type: CustomerWalletType;
  title: string;
  iconPath: string;
  prodEnabled: boolean;
}

export const walletOptions: CustomerWalletOption[] = [
  {
    type: "enaira",
    title: "eNaira",
    iconPath: eNairaIconPath,
    prodEnabled: true,
  },
  {
    type: "momo",
    title: "MoMo wallet",
    iconPath: momoIcon,
    prodEnabled: false,
  },
  {
    type: "pocket",
    title: "Pocket",
    iconPath: pocketIcon,
    prodEnabled: true,
  },
  {
    type: "palmpay",
    title: "PalmPay",
    iconPath: palmPayIcon,
    prodEnabled: false,
  },
];
